import React from 'react'
import "./Layout.css";
import img1 from "./../Assets/images/ShortReads/Authorities.jpg";
const ShortReads = () => {
    return (
        <div style={{ marginTop: '2%' }}>
            <center>
                <img id="sr_001" src={img1} alt="Authorities"    />
            </center>
            <center>
           
            </center>
        </div>
    )
}

export default ShortReads;