import React from 'react'
import { Table, Container, Row, Col } from 'reactstrap';
import "./Layout.css";
import ImgAreYouSaved from "./../Assets/images/AreYouSaved.PNG";

const StudyLinks = () => {
    return (
        <div style={{ marginTop: '5%' }}>

            <Container xs="12">
                <Row>
                    <Col xs="12" md="6">
                        <Table striped bordered  responsive className="tbl" >
                            <thead>
                                <tr>
                                    <th>Youtube Bible Studies <a href="https://www.youtube.com/channel/UCm3BsJimYXHNiK83sVmxW8Q/">(Full Channel Here)</a></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="https://www.youtube.com/watch?v=y4EIsMYsAkA&list=PL9dgKzE0bHVIYrRABLHV4IdgqUZMu-I6P" target="_blank" rel="noopener noreferrer">World Religion Series</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.youtube.com/watch?v=Q0U6tzIbHGk&list=PL9dgKzE0bHVJmomyuxV9QdawNVuSXmVf5" target="_blank" rel="noopener noreferrer">7 Mysteries in the Bible</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.youtube.com/watch?v=m3OiT6Q6Xws&list=PL9dgKzE0bHVLCNd0_zxUvB3ZQS5D6ScGL" target="_blank" rel="noopener noreferrer">OT Survey Series</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.youtube.com/watch?v=jQLnS5QymWk" target="_blank" rel="noopener noreferrer">Work or Labor in the Bible</a></td>
                                </tr>
                                <tr>
                                    <td><a href="https://www.youtube.com/watch?v=LdDuOn-ytPE" target="_blank" rel="noopener noreferrer">Satan Attacking the words of God (Another Book)</a></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs="12" md="4">
                    <img id="imgAreYouSaved" src={ImgAreYouSaved} alt="Are You Saved?"    />
                    </Col>
                </Row>
            </Container>
            <center>
           
            </center>
        </div>
    )
}
export default StudyLinks;