import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardFooter } from 'reactstrap'
import imgBanner from "./../Assets/images/Banner.jpg";
import imgCompassion from "./../Assets/images/Compassion.jpg";
import imgPreachTheGospel from "./../Assets/images/PreachTheGospel.jpg"
import { Container, Row, Col } from 'reactstrap';
const StreetPreachingMinistries = () => {
    return (
        <div style={{ margin: ".5%" }}>
            <center>
                <Container xs="12">
                    <Row>

                        <Col xs="12" md="12" lg="4" >
                            <br />
                            <Card className="div-event bg-common">
                                <CardHeader><b>Passion for the Truth</b></CardHeader>
                                <CardBody >
                                    <p>
                                        <b>Psalms 60:4
                                            <br /> Thou hast given a banner to them that fear thee, that it may be displayed because of the truth. Selah.</b>
                                    </p>
                                    <p>
                                        Display the TRUTH in every places!
                                        <br />


                                    </p>
                                    <p>
                                        <br />
                                        <img id="Banner" src={imgBanner} alt="Banner" height="372px" width="300px" />
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <br />
                        <Col xs="12" md="12" lg="4">
                            <br />
                            <Card className="div-event bg-common">
                                <CardHeader><b>Compassion to the Lost</b></CardHeader>
                                <CardBody >
                                    <p>
                                        <b>Mark 6:34 KJV
                                            <br /> And Jesus, when he came out, saw much people, and was moved with compassion toward them, because they were as sheep not having a shepherd:
                                            and he began to teach them many things.</b>
                                    </p>
                                    <p>
                                        Sharing the Gospel/Truth to the Lost as JESUS commanded
                                        <br />
                                    </p>
                                    <p>
                                        <br />
                                        <img id="imgCompassion" src={imgCompassion} alt="Compassion" height="300px" width="300px" />
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <br />
                        <Col xs="12" md="12" lg="4">
                            <br />
                            <Card className="div-event bg-common">
                                <CardHeader><b>Preach the Gospel</b></CardHeader>
                                <CardBody >
                                    <p>
                                        <b>Mark 16:15 KJV
                                            <br /> And he said unto them, Go ye into all the world, and preach the gospel to every creature.</b>
                                    </p>
                                    <p>
                                        Preaching the Gospel in every places!
                                        <br />
                                    </p>
                                    <p>
                                        <br />
                                        <img id="imgPreachTheGospel" src={imgPreachTheGospel} alt="Preach" height="370px" width="300px" />
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


            </center>
        </div>)
};


export default StreetPreachingMinistries;